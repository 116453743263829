.report
  h3
    color: #200e32
    font-weight: 900
    font-size: 24px
    line-height: 24px

  &__common
    margin: 1rem 0

  &__title
    padding: 0
    position: fixed
    overflow: hidden
    z-index: 9999

    .ant-page-header-heading-left
      align-items: baseline

.report-filter
  width: 100%
  display: flex
  flex-direction: column

  &__item > &__item
    margin-left: 10px

  &-title
    margin: 20px 0 20px 10px
    font-weight: bold

  .ant-checkbox-wrapper:first-child
    margin-left: 8px

  .ant-checkbox-wrapper
    margin-bottom: 8px

.gauge
  text-align: center !important
  &__title
    font-size: 1rem
    font-weight: "bold"
    &.green
      color: #25BA84
    &.blue
      color: #2C75F7
  &__subtitle
    font-weight: 900
    font-size: 18px
    line-height: 18px
    color: #2C2C2C
