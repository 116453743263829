.question
  &__body
    .ant-btn-sm
      height: 30px
      border: none
    &-preview
      color: #2C2C2C
      font-size: 16px
      line-height: 20px
    .ant-checkbox-wrapper
      margin-left: 0px
      line-height: 2.5

    .ant-slider-dot-active
      border-color: #f0f0f0

  &__header
    display: flex
    margin-bottom: 1rem

    .ant-btn
      height: 30px
      border: none
      //color: #200E32

    &-name
      color: #2C2C2C
      font-weight: bold
      font-size: 22px
      line-height: 30px
      margin-right: 1rem

.agreement
  max-width: 33%
