.header
  display: flex
  justify-content: space-between
  transition: height 0.2s linear
  background-color: transparent

  &__right
    display: flex

  &__profile
    background: #EDEDED
    color: #616161
    border-radius: 12px
    display: flex
    flex-direction: row
    align-content: center
    justify-content: space-between
    align-items: center
    padding: 0 16px
    margin: 5px 0 5px 10px

    &-avatar
      margin-right: 16px
      .ant-badge-count
        top: 5px
        right: 5px

    &-dropdown
      margin-left: 16px
      background-color: $medium-violet
      color: #fff
      height: 24px !important
