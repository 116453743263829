.content
  &__head
    .ant-page-header-heading-title
      color: $medium-violet

  &__body
    background: #FFFFFF
    border: 1px solid #EDEDED
    box-sizing: border-box
    border-radius: 16px
    padding: 10px 25px

    .ant-table
      .ant-table-thead tr
        margin-bottom: 10px
        .ant-table-cell
          background: #fff
          color: $dark-violet
          font-weight: bold
          font-size: 14px
          line-height: 18px
          border: 0
          text-overflow: ellipsis
          white-space: nowrap
          &::before
            display: none
      .ant-table-tbody
        .ant-table-row
          border: 1px solid #EDEDED
          box-shadow: 0px 3px 26px -5px rgb(33 31 31 / 10%)
          border-radius: 10px
          box-sizing: border-box
          margin-bottom: 10px
          max-width: 100%
          overflow: hidden
          &:hover > td
            background: #F2EEE6
          .ant-table-cell
            border: 0
            display: flex
            align-items: center
            .ant-select
              height: auto !important
            .ant-input
              height: auto !important
              &:focus
                background: #FAF9F7
                border: 1px solid #E0DBD1
                border-radius: 4px
            .ant-btn-circle
              height: 20px
              min-width: 20px
              width: 20px
              padding: 0
              border: 1px solid #200E32
              border-radius: 50%
              span
                display: block
                margin-top: 1px
              &:hover
                background-color: $black
                border-color: $black
                color: #fff

  .empty-researches
    display: flex
    flex-direction: column
    align-content: center
    flex-wrap: wrap
    justify-content: center
    align-items: center
    h3
      color: #2C2C2C
      font-weight: 900
      font-size: 24px
      line-height: 24px
      margin: 50px
    img
      width: 330px
    button
      min-width: 330px
      margin: 50px

.pages
  display: flex
