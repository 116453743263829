$medium-violet: #72677D
$background: #FAF9F7
$dark-violet: #200E32
$black: #2C2C2C
$active-hover: #F2EEE6

$box-radius: 16px
$control-radius: 8px
$button-radius: 6px

$control-height: 40px

@import-normalize
@import ~antd/dist/antd.css
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap")

body
  font-family: "Source Sans Pro", sans-serif

#root,
.ant-spin-nested-loading,
.ant-spin-container,
.ant-layout
  height: 100%

#root .ant-spin-spinning
  position: absolute
  width: 100%
  top: 50%
  font-weight: bold

.ant-layout
  background-color: $background

.ant-page-header
  padding: 20px 0 !important

a, .ant-btn-link
  color: $dark-violet
  &:hover
    color: $medium-violet

.ant-btn-circle.ant-btn-sm
  max-width: inherit

.ant-btn
  background: transparent
  font-weight: bold
  font-size: 16px
  line-height: 24px
  border-radius: $button-radius
  height: 40px
  &:hover, &:focus
    color: $medium-violet

.ant-btn-ghost, .ant-btn-default
  border-color: $dark-violet
  &:hover, &:focus
    border-color: $medium-violet

.ant-btn-link
  padding: 0
  margin: 0
  height: auto

.ant-btn-primary
  background: $dark-violet
  border-color: $dark-violet
  box-shadow: 0px 16px 20px -10px rgba(31, 9, 54, 0.6)
  color: #fff

  &:hover, &:focus
    background: $medium-violet
    color: $background

.ant-btn-lg
  height: 48px

.ant-btn-sm
  height: 32px !important

.ant-btn-background-ghost
  color: $black
  background: #F2EEE6 !important
  border: none

.ant-page-header-heading-extra .ant-btn-sm, .ant-tabs-extra-content .ant-btn-sm
  height: 36px !important
  font-size: 14px
  padding: 0 15px

.ant-tabs-tab
  font-weight: 400
  font-size: 16px
  line-height: 20px
  color: $medium-violet
  &.ant-tabs-tab-active
    .ant-tabs-tab-btn
      color: $black
.ant-tabs-ink-bar
  height: 3px !important
  background-color: $black

.ant-tabs-content
  font-size: 16px
  line-height: 24px
  color: $black

.ant-modal-content
  background: #FFFFFF
  box-shadow: 0px 16px 30px -10px rgba(32, 14, 50, 0.6)
  border-radius: $box-radius

  .ant-modal-header
    border-radius: $box-radius $box-radius 0 0
  .ant-modal-title
    text-align: center
    color: $black
    font-weight: bold
    font-size: 22px
    line-height: 30px

  .ant-modal-footer
    display: flex
    justify-content: space-between
    margin-top: 20px
    .ant-btn
      height: 36px
      border-radius: $button-radius

.ant-picker-panel-container
  border-radius: $box-radius

.ant-picker-date-panel, .ant-picker-time-panel, .ant-picker-ranges
  padding: 15px 20px !important

.ant-picker-cell-selected .ant-picker-cell-inner
  background: $black !important
  border-radius: $box-radius

.ant-picker-cell-today .ant-picker-cell-inner
  &::before
    border-radius: $box-radius !important
    border: 1px solid $black !important

.ant-input, .ant-select
  border-radius: $control-radius
  height: $control-height

.ant-input-group .ant-input-affix-wrapper
  input
    height: 30px
  &:not(:last-child)
    border-radius: $control-radius
    border-top-right-radius: 0
    border-bottom-right-radius: 0

.ant-input-group-addon
  &:last-child
    border-radius: $control-radius
    border-top-left-radius: 0
    border-bottom-left-radius: 0
    .ant-btn
      border: none

.ant-picker
  width: 100%
  height: $control-height
  border-radius: $control-radius

.ant-select
  width: 100%
  .ant-select-arrow, .ant-select-clear
    top: 50%
  &-item-option-active, &-item-option-selected
    background-color: #F2EEE6 !important

.ant-tree-select
  input, .ant-select-selection-item
    margin-top: 4px
  .ant-select-selection-search-input
    height: $control-height

.ant-select-selector
  border-radius: $control-radius !important
  height: $control-height !important
  input, .ant-select-selection-placeholder, .ant-select-selection-item
    margin-top: 4px

.ant-select-dropdown
  border-radius: $box-radius

.ant-dropdown-menu
  border-radius: $box-radius
  &-item
    padding: 10px 15px
  &-item-active
    background-color: $active-hover !important

.ant-pagination-prev, .ant-pagination-next
  .ant-pagination-item-link
    border-radius: 50%

.ant-pagination-item
  &-active
    border-radius: 50%
    background: $dark-violet
    border-color: $dark-violet
    a
      color: #fff

.ant-table-filter-dropdown-btns
  border-radius: 0 0 $button-radius $button-radius
  align-items: center
  padding: 10px

.ant-steps
  .ant-steps-item
    padding-left: 0 !important
  .ant-steps-item-icon
    border-radius: 8px
  .ant-steps-item-content
    display: block

  .ant-steps-item-wait
    .ant-steps-item-container[role=button]
      &:hover
        .ant-steps-item-icon
          border-color: $dark-violet !important
          span
            color: $dark-violet !important

  .ant-steps-item-active
    .ant-steps-item-icon
      background: $active-hover
      border-color: $dark-violet
      .ant-steps-icon
        color: $dark-violet

  .ant-steps-item-finish
    .ant-steps-item-container[role=button]
      &:hover
        .ant-steps-item-icon
          border-color: $dark-violet !important
          span
            color: #fff !important
    .ant-steps-item-icon
      border-color: $dark-violet
      background: $dark-violet
      color: #fff
      .ant-steps-icon
        color: #fff

.ant-select-disabled
  .ant-select-arrow
    display: none
  .ant-select-selector
    cursor: text !important
    color: inherit !important

.ant-checkbox-wrapper
  line-height: 120%
  margin-left: 0 !important
  margin-bottom: 10px
  .ant-checkbox-checked
    &::after
      border-color: $black
    .ant-checkbox-inner
      background-color: $black
      border-color: $black
    &:focus, &hover
      border-color: $black

.ant-checkbox-group
  display: flex
  flex-direction: column

.ant-slider
  &.ant-slider-with-marks
    height: 55px
    margin: 20px 0
  .ant-slider-rail, .ant-slider-track
    height: 16px
  .ant-slider-rail
    border-radius: 16px
    background: #F2EEE6
  .ant-slider-track
    background: #200E32
  .ant-slider-mark
    top: 30px
  .ant-slider-handle
    border: 7px solid #200E32
    margin-top: -5px
    width: 26px
    height: 26px
  .ant-slider-step
    height: 16px
    .ant-slider-dot
      width: 1px
      border-radius: 0
      border: none
      background-color: #F2EEE6
      top: 18px
    .ant-slider-dot
      background-color: #C0BBC5

.radio-vertical
  display: flex
  flex-direction: column

  .ant-radio-wrapper
    margin-bottom: 20px

.ant-alert-info
  background: transparent
  border: 1px solid #200E32
  border-radius: 14px
  .ant-alert-message
    color: #2C2C2C
  .ant-alert-description
    color: #72677D

.ant-alert-warning
  background: #FFEAB7
  border-radius: 12px
  .ant-alert-description
    color: #200E32
.stage-content
  padding-bottom: 30px
  &::-webkit-scrollbar
    width: 6px
    height: 6px
    background-color: #fff
  &::-webkit-scrollbar-thumb
    border-radius: 6px
    background-color: #72677D
  &::-webkit-scrollbar-track
    border-radius: 6px
    background-color: #eeedf2

.goal-switch.ant-switch-checked
  background-color: #000

@import header
@import content
@import question
@import report
@import login
